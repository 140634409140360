import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="welcomepage">
      <div className="welcome-main">
        <div className="wc-slogon">
          {t('welcome1')}
          <br />
          {t('welcome2')}
        </div>
        <div className="links-group">
          <RouterLink to="/Learn" className="button-links">
            <img className="welcome-icon" src="imgs/info.svg" alt="info icon"/>
            <p className="icon-text">{t('homeleftButton')}</p>
          </RouterLink>
          <RouterLink to="/Chat" className="button-links">
            <img className="welcome-icon" src="imgs/message.svg" alt="message icon"/>
            <p className="icon-text">{t('homerightButton')}</p>
          </RouterLink>
        </div>
      </div>

      <img className="right-img" src="imgs/right.svg" alt="illustration right" />
      <img className="left-img" src="imgs/left.svg" alt="illustration left" />
    </div>
  );
};

export default Home;
